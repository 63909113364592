<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Головна" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-toggle label="Show object block *" small v-model="showBlock"/>
                <va-input
                  label="Object page title *"
                  v-model="title"
                  :messages="['The recommended number of characters is 70']"
                  :error="!!errors.objectpage_title"
                  :error-messages="errors.objectpage_title"
                  @input="delete errors.objectpage_title"
                />
                <va-input
                  label="Object page item label before *"
                  v-model="itemBefore"
                  :messages="['The recommended number of characters is 15']"
                  :error="!!errors.objectpage_item_before_label"
                  :error-messages="errors.objectpage_item_before_label"
                  @input="delete errors.objectpage_item_before_label"
                />
                <va-input
                  label="Object page item label after *"
                  v-model="itemAfter"
                  :messages="['The recommended number of characters is 15']"
                  :error="!!errors.objectpage_item_after_label"
                  :error-messages="errors.objectpage_item_after_label"
                  @input="delete errors.objectpage_item_after_label"
                />
                <va-input
                  label="Object page item first label *"
                  v-model="itemFirstLabel"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.objectpage_item_first_label"
                  :error-messages="errors.objectpage_item_first_label"
                  @input="delete errors.objectpage_item_first_label"
                />
                <va-input
                  label="Object page item second label *"
                  v-model="itemSecondLabel"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.objectpage_item_second_label"
                  :error-messages="errors.objectpage_item_second_label"
                  @input="delete errors.objectpage_item_second_label"
                />
                <va-input
                  label="Object page item third label *"
                  v-model="itemThirdLabel"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.objectpage_item_third_label"
                  :error-messages="errors.objectpage_item_third_label"
                  @input="delete errors.objectpage_item_third_label"
                />
                <va-input
                  label="Object page item file label *"
                  v-model="itemFileLabel"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.objectpage_item_file_label"
                  :error-messages="errors.objectpage_item_file_label"
                  @input="delete errors.objectpage_item_file_label"
                />
                <va-input
                  label="Object page filter type label *"
                  v-model="filterTypeLabel"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.objectpage_filter_type_label"
                  :error-messages="errors.objectpage_filter_type_label"
                  @input="delete errors.objectpage_filter_type_label"
                />
                <va-input
                  label="Object page filter year label *"
                  v-model="filterYearLabel"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.objectpage_filter_year_label"
                  :error-messages="errors.objectpage_filter_year_label"
                  @input="delete errors.objectpage_filter_year_label"
                />
                <va-input
                  label="Object page filter region label *"
                  v-model="filterRegionLabel"
                  :messages="['The recommended number of characters is 30']"
                  :error="!!errors.objectpage_filter_region_label"
                  :error-messages="errors.objectpage_filter_region_label"
                  @input="delete errors.objectpage_filter_region_label"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      showBlock: false,
      title: '',
      itemBefore: '',
      itemAfter: '',
      itemFirstLabel: '',
      itemSecondLabel: '',
      itemThirdLabel: '',
      itemFileLabel: '',
      filterTypeLabel: '',
      filterYearLabel: '',
      filterRegionLabel: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        objectpage_show: this.showBlock,
        objectpage_title: this.title,
        objectpage_item_before_label: this.itemBefore,
        objectpage_item_after_label: this.itemAfter,
        objectpage_item_first_label: this.itemFirstLabel,
        objectpage_item_second_label: this.itemSecondLabel,
        objectpage_item_third_label: this.itemThirdLabel,
        objectpage_item_file_label: this.itemFileLabel,
        objectpage_filter_type_label: this.filterTypeLabel,
        objectpage_filter_year_label: this.filterYearLabel,
        objectpage_filter_region_label: this.filterRegionLabel,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/12`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/validate/12`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/12`)
        .then(response => {
          // console.log(response.data)
          this.showBlock = !!response.data.objectpage_show
          this.title = response.data.objectpage_title
          this.itemBefore = response.data.objectpage_item_before_label
          this.itemAfter = response.data.objectpage_item_after_label
          this.itemFirstLabel = response.data.objectpage_item_first_label
          this.itemSecondLabel = response.data.objectpage_item_second_label
          this.itemThirdLabel = response.data.objectpage_item_third_label
          this.itemFileLabel = response.data.objectpage_item_file_label
          this.filterTypeLabel = response.data.objectpage_filter_type_label
          this.filterYearLabel = response.data.objectpage_filter_year_label
          this.filterRegionLabel = response.data.objectpage_filter_region_label
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
